import './Home.css'

const Home = () => {
    return (
        <div id='Home'>
            <h1 className='name'>Jonathan Taylor</h1>
        </div>
    )
}

export default Home
